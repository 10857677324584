import React, { useCallback, useState } from "react";
import * as styles from "./Contact.module.scss";

interface Props extends React.HTMLAttributes<HTMLElement> {}

export const Contact: React.FC<Props> = ({ children, className, ...props }) => {
    return (
        <>
            <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className={styles.base}
                action="/success"
            >
                <fieldset>
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <label htmlFor="name">Full name:</label>
                    <input name="name" type="text" id="name" />
                    <label>Your email address:</label>
                    <input name="email" type="email" id="email" />
                    <label>Subject:</label>
                    <input name="subject" type="text" id="subject" />
                    <label>Message:</label>
                    <textarea name="message" id="message"></textarea>
                </fieldset>
                <input type="submit" value="Send Message" />
            </form>
        </>
    );
};
export default Contact;
