import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import { Contact } from "../components/Contact";
import SEO from "../components/seo";

const IndexPage = () => (
    <Layout>
        <Helmet>
            <title>Contact</title>
            <meta name="description" content="Contact" />
        </Helmet>
        <SEO title="Contact" />
        <Contact />
    </Layout>
);

export default IndexPage;
